@import './base/all';
@import './vendors/all';

body {
    height: 100vh;
    margin: 0;
    background-color: $primary-colour;

    @media screen and (min-width: $breakpoint-md) {
        background-image: $body-bg-image;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
