.mb-contact-us {
    width: 100%;

    @media screen and (min-width: $breakpoint-md) {
        max-width: 800px;
    }

    &__container {
        padding: $outer-padding;
        background: rgba(0, 0, 0, 0.6);
        border: 2px solid $black;
    }

    &__heading {
        margin-top: 0;
    }

    &__copy {
        font-weight: $bold-font-weight;
    }

    &__form {
        textarea {
            width: 100%;
            padding: $outer-padding / 2;
            font-family: $secondary-font;
            font-weight: $medium-font-weight;
        }
    }
}
