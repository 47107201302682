.Toastify {
    .mb-contact-us__form & {
        &__toast {
            min-height: 50px;
        }

        &__toast-body {
            margin: 0;
            padding: 0 6px;
            font-family: $secondary-font;
            font-weight: $medium-font-weight;
            font-size: $regular-font-size - 0.1;
            font-weight: $bold-font-weight;
            color: $primary-colour;
        }

        &__close-button {
            svg {
                fill: $primary-colour;
            }
        }
    }
}
