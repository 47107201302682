@font-face {
    font-family: 'helveticaNeue';
    src: local('helveticaNeue'),
        url(../fonts/HelveticaNeueLTStd-Bd.otf) format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500&display=swap');

$primary-font: 'helveticaNeue';
$secondary-font: 'Poppins', sans-serif;
$regular-font-size: 1rem;
$regular-font-weight: 200;
$medium-font-weight: 300;
$bold-font-weight: 400;
$extra-bold-font-weight: 500;
$h1-font-size: 1.4rem;

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: $regular-font-weight;
    font-size: $regular-font-size;
    color: $white;
}

a,
h1,
h2,
button {
    font-family: $secondary-font;
    font-weight: $medium-font-weight;
}

h1 {
    font-size: $h1-font-size;
}

h2 {
    font-weight: $extra-bold-font-weight;
}
