.mb__form-field {
    display: flex;

    &:not(last-child) {
        margin-bottom: $outer-padding;
    }

    input {
        height: $input-height;
        flex-grow: 1;
        padding-left: 10px;
        border: none;
        font-family: $secondary-font;
        font-weight: $medium-font-weight;
    }
}
