.container {
    max-width: $site-width;
    margin: 0 auto;
    padding: 0 $outer-padding;

    .inner-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
            margin-bottom: $section-spacing-b;
        }
    }
}
